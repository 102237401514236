import { COUNTER_API } from "../constants";
export const authProvider = {
  login: async ({ username, password }) => {
    const response = await fetch(`${COUNTER_API}/api/admin/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    });
    const { status, statusText } = response;

    const { token, error, email, name, id } = await response.json();

    if (status !== 200) {
      const errorMessage = (error && error.message) || statusText;
      return Promise.reject(errorMessage);
    }

    localStorage.setItem("token", token);
    localStorage.setItem("username", email);
    localStorage.setItem("name", name);
    localStorage.setItem("userId", id);

    return Promise.resolve(token);
  },
  logout: () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    return Promise.resolve();
  },
  checkError: (e) => {
    if (e.status === 401) return Promise.reject();
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () =>
    Promise.resolve({
      fullName: localStorage.getItem("name"),
      email: localStorage.getItem("username"),
      id: localStorage.getItem("userId"),
    }),
};
