import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";

export const UnallocatedreservationlogEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="channel" disabled />
      <TextInput source="bookingSource" disabled />
      <TextInput source="reservation.sourceResvID" disabled />
      <TextInput source="reservation.code" disabled />
      <TextInput source="property.name" disabled />
      <TextInput source="reason" />
      <TextInput source="comment" />
      <BooleanInput source="isOverbooking" />
    </SimpleForm>
  </Edit>
);
