import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  NumberField,
  useRecordContext,
} from "react-admin";

//mui
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useStyles } from "./Property.styles";

//sidebar
import { Sidebar } from "../../components/Sidebar/Sidebar";

const PropertyTitle = () => {
  const record = useRecordContext();
  if (!record) return "No title";
  return <span>{record.name}</span>;
};

export const PropertyShow = (props) => {
  const classes = useStyles();

  return (
    <Show emptyWhileLoading aside={<Sidebar />} title={<PropertyTitle />}>
      <Grid container sx={{ paddingTop: 4, width: "100%" }}>
        <Grid item xs={12}>
          <SimpleShowLayout
            divider={<Divider flexItem />}
            className={classes.showList}
          >
            <TextField source="address.country" label="Address" />
            <TextField source="dateFormat" label="Date" />
            <BooleanField source="autoCustomerTag" />
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
          </SimpleShowLayout>

          <SimpleShowLayout
            divider={<Divider flexItem />}
            className={classes.showList}
          >
            <TextField source="currency" label="Currency" />
            <TextField source="timezone" label="Timezone" />
            <NumberField source="code" label="Postcode" />
            <TextField source="id" label="Property Id" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};
