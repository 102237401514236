import { useTranslate } from "react-admin";
import { useDialogData } from "../../../context/dialogContext";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useStyles } from "./DialogContent.styles";

const UserResetPasswordContent = () => {
  const { dialogData } = useDialogData();
  const translate = useTranslate();
  const classes = useStyles();

  const { title, userName, userId, userEmail } = dialogData;
  return (
    <Box className={classes.resetPassBox}>
      <Typography variant="h5" className={classes.title}>
        {translate(title)}
      </Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.userName"
      )}: ${userName} `}</Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.userId"
      )}: ${userId} `}</Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.userEmail"
      )}: ${userEmail} `}</Typography>
    </Box>
  );
};

export default UserResetPasswordContent;
