import { required, SelectInput } from "react-admin";
import { selectProps, useStyles } from "./AlertsSelect.styles";

const AlertsSelect = ({ source, choices, optionText, format }) => {
  const classes = useStyles();
  return (
    <SelectInput
      source={source}
      choices={choices}
      optionText={optionText}
      optionValue="id"
      fullWidth
      className={classes.selectRoot}
      SelectProps={selectProps}
      validate={[required()]}
      format={format}
    />
  );
};

export default AlertsSelect;
