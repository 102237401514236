import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

import { RichTextInput } from "ra-input-rich-text";

import Grid from "@material-ui/core/Grid";
import AlertsSelect from "../../components/AlertsSelect/AlertsSelect";

import {
  locationChoices,
  levelChoices,
  locationsMap,
  levelMap,
} from "./alertsOptionsConfig";

const AlertsTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.name}` : ""}</span>;
};

const AlertsEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();

  const onSuccess = () => {
    notify("ca.notifications.success", {
      type: "success",
      undoable: false,
    });
    redirect(`/alerts`);
    refresh();
  };
  const onError = (error) => {
    const errorMessage = error.message || "ca.notifications.badRequest";
    notify(errorMessage, { type: error });
  };

  const formatSelectValue = (value, data) => {
    return Object.values(data).some((el) => el["id"] === value)
      ? data[translate(value)].id
      : data[value].id;
  };
  const transform = (data) => ({
    ...data,
    level: translate(data.level),
    location: translate(data.location),
  });

  return (
    <Edit
      title={<AlertsTitle />}
      transform={transform}
      mutationOptions={{ onSuccess, onError }}
      mutationMode="pessimistic"
    >
      <SimpleForm>
        <Grid container spacing={4} sx={{ paddingTop: 4 }}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextInput
                source="name"
                label="Alert Name"
                validate={[required()]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AlertsSelect
                choices={locationChoices}
                source="location"
                optionText={({ name }) => translate(name)}
                format={(value) => formatSelectValue(value, locationsMap)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AlertsSelect
                choices={levelChoices}
                source="level"
                optionText={({ name, color }) => (
                  <span style={{ color: `${color}` }}>{translate(name)}</span>
                )}
                format={(value) => formatSelectValue(value, levelMap)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <RichTextInput
              label={translate("ca.alerts.richText.label")}
              source="message"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default AlertsEdit;
