import { createContext, useContext, useState } from "react";

const DialogContext = createContext();

const DialogProvider = ({ children }) => {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [dialogData, setDialogData] = useState({});
  const [accountPlan, setAccountPlan] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );


  return (
    <DialogContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        textFieldValue,
        setTextFieldValue,
        dialogData,
        setDialogData,
        accountPlan,
        setAccountPlan,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

const useDate = () => {
  const context = useContext(DialogContext);
  const { startDate, setStartDate, endDate, setEndDate } = context;
  if (context === undefined) {
    throw new Error("useDateContext");
  }
  return { startDate, setStartDate, endDate, setEndDate };
};

const useTextField = () => {
  const context = useContext(DialogContext);
  const { textFieldValue, setTextFieldValue } = context;
  if (context === undefined) {
    throw new Error("useTextFieldContext");
  }
  return { textFieldValue, setTextFieldValue };
};

const useDialogData = () => {
  const context = useContext(DialogContext);
  const { dialogData, setDialogData } = context;
  if (context === undefined) {
    throw new Error("useDialogDataContext");
  }
  return { dialogData, setDialogData };
};

const useAccountPlan = () => {
  const context = useContext(DialogContext);
  const { accountPlan, setAccountPlan } = context;
  if (context === undefined) {
    throw new Error("useAccountPlanContext");
  }
  return { accountPlan, setAccountPlan };
};

export { DialogProvider, useDate, useTextField, useDialogData, useAccountPlan };
