import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { stringify } from "query-string";

import { COUNTER_API, WORKSPACE_API } from "../constants";

const getHost = (resource) => {
  const WORKSPACE_RESOURCES = [
    "spaces",
    "users",
    "updateSpaceCurrency",
    "subscription",
    "wdAdmin",
  ];

  const returnWorkspaceAPI = WORKSPACE_RESOURCES.includes(resource);

  return returnWorkspaceAPI ? WORKSPACE_API : COUNTER_API;
};

const DEFAULT = "DEFAULT";

const getEndpoint = (resource, method = DEFAULT) => {
  const ENDPOINT_MAPS = {
    DEFAULT: {
      properties: "api/admin/property",
      hw_properties: "api/admin/hw-properties",
      spaces: "api/admin/spaces",
      updateSpaceCurrency: "api/space",
      updatePropertyCurrency: "intercom/property",
      users: "api/admin/users",
      admin: "api/admin",
      wdAdmin: "api/systemadmins",
      roomTypes: "intercom/beds/roomType",
      subscription: "api/admin/subscription",
      alerts: "api/admin/alert",
      channexMapping: "channex/admin/mappings/bulk",
      emails: "api/emails",
      debugAvailability: "api/calculateAvailability/dryRun",
      unallocatedReservationLogs: "api/admin/unallocated-reservations",
      cnxMapping: "channex/property",
    },
    POST: {
      debugAvailability: "api/calculateAvailability",
      admin: "api/admin",
    },
    PATCH: {
      properties: "intercom/property",
    },
    DELETE: {
      cnxMapping: "channex/property",
    },
  };

  const endpoint =
    (ENDPOINT_MAPS[method] && ENDPOINT_MAPS[method][resource]) ||
    ENDPOINT_MAPS[DEFAULT][resource] ||
    resource;

  return endpoint;
};

export const httpClient = (url, options = {}) => {
  const authToken = localStorage.getItem("token");
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.headers.set("Access-Control-Expose-Headers", "Content-Range");
  options.headers.set("Authorization", `Bearer ${authToken}`);
  return fetchUtils
    .fetchJson(url, options)
    .catch((e) => ({ data: [], total: 0, error: "e.message" }));
};

const dataProvider = simpleRestProvider(COUNTER_API, httpClient);

const customDataProvider = {
  ...dataProvider,
  getList: (resource, params = {}) => {
    const { pagination = {}, sort = {}, filter = {} } = params;

    const { page, perPage } = pagination;
    const { field, order } = sort;
    const { disablePagination, ...providedQuery } = filter;

    const host = getHost(resource);

    const sortOrder = order === "DESC" ? -1 : 1;

    const filters = Object.keys(providedQuery)
      .filter((filterKey) => !!providedQuery[filterKey])
      .map((filterKey) => ({ [filterKey]: providedQuery[filterKey] }))
      .reduce((filters, filterObj) => ({ ...filters, ...filterObj }), {});

    const query = disablePagination
      ? filters
      : {
          sort: [field, sortOrder],
          limit: perPage,
          offset: page - 1,
          ...filters,
        };

    const endpoint = getEndpoint(resource);

    const url =
      resource === "roomTypes"
        ? `${host}/${endpoint}/${params.propertyId}?isAdminRoute=true`
        : `${host}/${endpoint}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      if (!json) return { data: [], total: 0 };

      if (resource === "debugAvailability") {
        json = json.occupiedCountMap;
      }
      const data = Array.isArray(json) ? json : json.data || [];

      const total = Array.isArray(json) ? json.length : json.total || 0;
      return {
        data: data.map((el) => ({ ...el, id: el._id })),
        total,
      };
    });
  },
  getMany: (resource, params = {}) => {
    const host = getHost(resource);
    const { pagination = {}, sort = {}, filter = {} } = params;
    const { page, perPage } = pagination;
    const { field, order } = sort;
    const { disablePagination, ...providedQuery } = filter;

    const sortOrder = order === "DESC" ? -1 : 1;

    const filters = Object.keys(providedQuery)
      .filter((filterKey) => !!providedQuery[filterKey])
      .map((filterKey) => ({ [filterKey]: providedQuery[filterKey] }))
      .reduce((filters, filterObj) => ({ ...filters, ...filterObj }), {});

    const query = disablePagination
      ? filters
      : {
          sort: [field, sortOrder],
          limit: perPage,
          offset: page - 1,
          ...filters,
        };

    const endpoint = getEndpoint(resource);

    const url =
      resource === "roomTypes"
        ? `${host}/${endpoint}/${params.propertyId}`
        : `${host}/${endpoint}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      if (!json) return { data: [], total: 0 };

      const data = Array.isArray(json) ? json : json.data || [];

      const total = Array.isArray(json) ? json.length : json.total || 0;
      return {
        data: data.map((el) => ({ ...el, id: el._id })),
        total,
      };
    });
  },
  getOne: (resource, params) => {
    const endpoint = getEndpoint(resource);
    return dataProvider.getOne(endpoint, params).then(({ data }) => ({
      data: { ...data, id: data._id },
    }));
  },
  update: (resource, params) => {
    const host = getHost(resource);
    const endpoint = getEndpoint(resource, "PATCH");
    const url = `${host}/${endpoint}/${params.id}?isAdminRoute=true`;

    return httpClient(url, {
      ...httpClient.options,
      body: JSON.stringify(params.data),
      method: "PATCH",
    }).then((res) => {
      return {
        data: { ...res, id: res._id },
      };
    });
  },
  updateMany: (resource, params) => {
    const host = getHost(resource);
    const endpoint = getEndpoint(resource);
    const url = `${host}/${endpoint}`;

    const data = {
      ...params.data,
      ids: params.ids,
    };
    return httpClient(url, {
      ...httpClient.options,
      body: JSON.stringify(data),
      method: "POST",
    }).then((res) => {
      const data = params.ids.map((id) => ({ id, _id: id, ...params.data }));
      return {
        data,
      };
    });
  },
  create: (resource, params) => {
    const { data, meta } = params;

    const host = getHost(resource);

    const endpoint = getEndpoint(resource, "POST");
    let url = `${host}/${endpoint}`;

    if (meta?.filterValues) url += `?${stringify(meta.filterValues)}`;

    return httpClient(url, {
      ...httpClient.options,
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: { ...json, id: json._id } }));
  },
  delete: (resource, params) => {
    const host = getHost(resource);

    const endpoint = getEndpoint(resource);
    const url = `${host}/${endpoint}/${params.id}`;

    return httpClient(url, {
      ...httpClient.options,
      method: "DELETE",
    }).then(({ json }) => ({ data: { ...json, id: json._id } }));
  },
  deleteMany: (resource, params) => {
    const host = getHost(resource);

    const endpoint = getEndpoint(resource);
    const queryParams = { ids: params.ids };
    const url = `${host}/${endpoint}?${stringify(queryParams)}`;

    return httpClient(url, {
      ...httpClient.options,
      method: "DELETE",
    }).then((res) => {
      const data = params.ids.map((id) => ({ id, _id: id, ...params.data }));
      return {
        data,
      };
    });
  },
  changePassword: ({ userID, password: newPassword }) => {
    const url = `${WORKSPACE_API}/api/users/changePassword`;

    return httpClient(url, {
      ...httpClient.options,
      method: "PATCH",
      body: JSON.stringify({ userID, newPassword }),
    })
      .then((res) => {
        if (res.status === 200) {
          return { data: { userID, newPassword } };
        }
      })
      .catch((e) => {
        const message = e.message || "ca.notifications.badRequest";
        throw new Error(message);
      });
  },
};

export default customDataProvider;
