import { useState } from "react";
import {
  useTranslate,
  FunctionField,
  useNotify,
  useRefresh,
} from "react-admin";

//mui
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { colors } from "../../theme";

import dataProvider from "../../providers/dataProvider";
import { useStyles } from "./AlertsListPopover.styles";

const activationStatusLabel = {
  activateAlert: "ca.popover.activateAlert.name",
  deactivateAlert: "ca.popover.deactivateAlert.name",
};

const CustomPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleUpdateAlert = (record) => {
    const { id, status } = record;

    dataProvider
      .update("alerts", {
        id: id,
        data: { ...record, status: !status },
      })
      .then(({ status }) => {
        if (status >= 200 && status < 300)
          return notify("ca.notifications.success", {
            type: "success",
          });
        refresh();
      })
      .catch((error) => {
        const errorMessage = error.message || "ca.notifications.badRequest";
        notify(errorMessage, { type: "error" });
      });
    handleClose();
  };

  const handleDeleteAlert = (record) => {
    const { id } = record;
    dataProvider
      .delete("alerts", { id: id, data: record })
      .then(({ status }) => {
        if (status)
          return notify("ca.notifications.success", {
            type: "success",
          });
        refresh();
      })
      .catch((error) => {
        const errorMessage = error.message || "ca.notifications.badRequest";
        notify(errorMessage, { type: "error" });
      });
  };

  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.popoverStyle}
      >
        <FunctionField
          render={(record) => {
            const updatedLabel = record.status
              ? activationStatusLabel.deactivateAlert
              : activationStatusLabel.activateAlert;
            return (
              <Button
                size="small"
                variant="outlined"
                fullWidth
                onClick={() => handleUpdateAlert(record)}
                startIcon={
                  <PowerSettingsNewIcon style={{ color: colors.green }} />
                }
              >
                {translate(updatedLabel)}
              </Button>
            );
          }}
        />

        <FunctionField
          render={(record) => (
            <Button
              size="small"
              variant="outlined"
              fullWidth
              onClick={() => handleDeleteAlert(record)}
              startIcon={<DeleteIcon style={{ color: colors.red }} />}
            >
              {translate("ca.popover.deleteAlert.name")}
            </Button>
          )}
        />
      </Popover>
    </div>
  );
};

export default CustomPopover;
