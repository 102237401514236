import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  useRecordContext,
  CreateButton,
  FunctionField,
  EditButton,
  TextInput,
  FilterForm,
  useTranslate,
} from "react-admin";

//mui
import CachedIcon from "@mui/icons-material/Cached";
import { colors } from "../../theme";

import AlertListPopover from "../../components/AlertListPopover/AlertListPopover";

const AlertActions = () => {
  const translate = useTranslate();

  const searchFilter = [
    <TextInput
      label={translate("ca.searchInput.label")}
      source="q"
      resettable
      alwaysOn
    />,
  ];
  return (
    <TopToolbar sx={{ width: "100%" }}>
      <FilterForm filters={searchFilter} sx={{ flex: 1 }} />
      <CreateButton
        variant="contained"
        label={translate("ca.alerts.buttons.addNewAlert.label")}
        size="large"
        sx={{ textTransform: "none!important", fontWeight: "bold" }}
      >
        <CachedIcon />
      </CreateButton>
    </TopToolbar>
  );
};

const AlertFiled = () => {
  const { level } = useRecordContext();

  const updatedColors =
    level === "high"
      ? colors.red
      : level === "medium"
      ? colors.yellow
      : colors.green;

  return (
    <span style={{ color: `${updatedColors}`, fontWeight: "bold" }}>
      {level}
    </span>
  );
};

const AlertsList = () => (
  <>
    <List actions={<AlertActions />}>
      <Datagrid
        sx={{
          "& .RaDatagrid-rowCell:last-child": {
            textAlign: "end",
            "&:hover": {
              fill: {
                color: colors.primary,
              },
            },
          },
        }}
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <AlertFiled source="level" />
        <TextField source="location" />

        <FunctionField
          source="status"
          render={({ status }) =>
            status ? (
              <span style={{ color: colors.default }}>active</span>
            ) : (
              <span style={{ color: colors.dark }}>inactive</span>
            )
          }
        />
        <EditButton variant="outlined" />
        <AlertListPopover />
      </Datagrid>
    </List>
  </>
);

export default AlertsList;
