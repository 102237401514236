import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";

import { RichTextInput } from "ra-input-rich-text";
import AlertsSelect from "../../components/AlertsSelect/AlertsSelect";

import Grid from "@material-ui/core/Grid";
import { locationChoices, levelChoices } from "./alertsOptionsConfig";

const AlertsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();

  const onSuccess = () => {
    notify("ca.notifications.success", {
      type: "success",
      undoable: false,
    });
    redirect(`/alerts`);
    refresh();
  };
  const onError = (error) => {
    const errorMessage = error.message || "ca.notifications.badRequest";
    notify(errorMessage, { type: error });
  };

  const transform = (data) => ({
    ...data,
    level: translate(data.level),
    location: translate(data.location),
  });

  return (
    <Create
      title={translate("ca.alerts.buttons.addNewAlert.label")}
      mutationOptions={{ onSuccess, onError }}
      transform={transform}
    >
      <SimpleForm>
        <Grid container spacing={4} sx={{ paddingTop: 4 }}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextInput
                source="name"
                label="Alert Name"
                validate={[required()]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AlertsSelect
                choices={locationChoices}
                source="location"
                optionText="name"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AlertsSelect
                choices={levelChoices}
                source="level"
                optionText={({ name, color }) => (
                  <span style={{ color: `${color}` }}>{translate(name)}</span>
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <RichTextInput
              label={translate("ca.alerts.richText.label")}
              source="message"
              validate={[required()]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default AlertsCreate;
