import { defaultTheme } from "react-admin";

export const colors = {
  primary: "#fff",
  secondary: "#56A3A7",
  tertiary: "#7A6F9B",
  appBar: "#006799",
  dark: "#000",
  default: "#1976d2",
  red: "#d32f2f",
  green: "#388e3c",
  yellow: "#ffb74d",
};
export const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-row": {
            backgroundColor: colors.primary,
            "& > td, & > tr": {
              color: colors.dark,
            },
            "& .MuiButton-root": {
              color: colors.default,
            },
            "& .RaDatagrid-checkbox": {
              "& > svg": {
                fill: colors.dark,
              },
            },
            "&:hover": {
              backgroundColor: `${colors.tertiary}!important`,
              "& .RaDatagrid-checkbox": {
                "& > svg": {
                  fill: `${colors.primary}!important`,
                },
              },
              "& .MuiButton-root": {
                color: `${colors.primary}!important`,
              },
              " & > td": {
                "& > svg": {
                  fill: `${colors.primary}!important`,
                },
                "& > span, & > a": {
                  color: `${colors.primary}!important`,
                  "& > div": {
                    color: `${colors.primary}!important`,
                  },
                },
              },
            },
          },

          "& .RaDatagrid-headerCell": {
            backgroundColor: colors.secondary,
            color: colors.primary,
            "& .MuiButtonBase-root": {
              color: `${colors.primary}!important`,
              "& > svg": {
                fill: colors.primary,
              },
            },
            fontSize: "18px",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    "RaLabeled-label": {
      fontSize: 12,
    },
  },
};
