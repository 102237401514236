import { useTranslate } from "react-admin";
import { useDialogData } from "../../../context/dialogContext";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useStyles } from "./DialogContent.styles";

const SpaceDialogContent = () => {
  const {
    dialogData: { spaceName, spaceId, title = "" },
  } = useDialogData();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h5" className={classes.title}>
        {translate(title)}
      </Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.spaceName"
      )}: ${spaceName} `}</Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.spaceId"
      )}: ${spaceId} `}</Typography>
    </Box>
  );
};

export default SpaceDialogContent;
