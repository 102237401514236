import { useDate } from "../../context/dialogContext";
import PropertyDialogContent from "./DialogContent/PropertyDialogContent";
import CustomDatePicker from "../DatePicker/DatePicker";

import Box from "@mui/material/Box";

const DateComponent = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useDate();

  return (
    <Box>
      <PropertyDialogContent />
      <CustomDatePicker
        label="start date"
        date={startDate}
        setDate={setStartDate}
      />
      <CustomDatePicker label="end date" date={endDate} setDate={setEndDate} />
    </Box>
  );
};

export default DateComponent;
