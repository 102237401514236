import { PropertyList } from "./PropertyList";
import { PropertyShow } from "./PropertyShow";
import { PropertyEdit } from "./PropertyEdit";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const PropertyPages = {
  list: PropertyList,
  show: PropertyShow,
  edit: PropertyEdit,
  icon: LocationOnIcon,
};

export default PropertyPages;
