import englishMessages from "ra-language-english";

export const messages = {
  ...englishMessages,
  ca: {
    buttons: {
      property: {
        label: "Property Actions",
        cleanup: {
          name: "Cleanup",
          dialogWarning: `This action is performing following actions detecting and resolving overlapping reservations, removing duplicate availabilities and cleaning up allocations`,
          dialogButtonLabel: "CLEAN UP",
        },
      },
      mappings: {
        label: "Mappings",
        hw: {
          name: "Connect HostelWorld",
          dialogButtonLabel: "Connect",
        },
        hw_disconnect: {
          name: "Disconnect HostelWorld",
          dialogTitle:
            "Are you sure you want to disconnect following property from HostelWorld?",
          dialogButtonLabel: "Disconnect",
        },
        cnx_disconnect: {
          name: "Disconnect Channex",
          dialogTitle:
            "Are you sure you want to disconnect following property from Channex?",
          dialogButtonLabel: "Disconnect",
        },
      },
      full_refresh: {
        label: "Full Refresh",
        full_cleanup: { name: "Full Cleanup", dialogButtonLabel: "REFRESH" },
        restrictions_hw: {
          name: "Restrictions HW",
          dialogButtonLabel: "REFRESH",
        },
        refresh_channex: {
          name: "Refresh Channex",
          dialogButtonLabel: "REFRESH",
        },
        refresh_myAllocator: {
          name: "Refresh MyAllocator",
          dialogButtonLabel: "REFRESH",
        },
      },
      rooms: {
        label: "Rooms",
        reset_capacity: {
          name: "Reset capacity",
          dialogButtonLabel: "RESET",
          dialogTitle: "Reset capacity per Room Type",
          dialogWarning:
            "This action has potential destructive consequences! After triggering reset capacity rooms might go in negative availability for some time ( tens of minutes ), until the reset capacity  action is totally completed",
        },
      },
      reservations: {
        label: "Reservations",
        clean_reservations: {
          name: "Clean Up Reservations",
          dialogButtonLabel: "CLEAN UP",
        },
        pull_hw_reservations: {
          name: "Pull HW Reservations",
          dialogButtonLabel: "PULL RESERVATIONS",
        },
        pull_hw_reservation_by_id: {
          name: "Pull HW Reservation by ID",
          dialogButtonLabel: "PULL RESERVATION",
          inputLabel: "Reservation Id",
        },
        pull_mya_reservation_by_id: {
          name: "Pull MyAllocator Reservation by ID",
          dialogButtonLabel: "PULL RESERVATION",
          inputLabel: "Reservation Id",
        },
      },
      allocations: {
        label: "Allocations",
        clean_up_orphans: {
          name: "Clean Up Orphans",
          dialogButtonLabel: "CLEAN UP",
        },
        clean_up_dummy: {
          name: "Clean Up Dummy",
          dialogButtonLabel: "CLEAN UP",
        },
      },
      availability: {
        label: "Availability",
        refresh_availability: {
          name: "Refresh Availability",
          dialogButtonLabel: "REFRESH",
        },
        remove_duplicate: {
          name: "Remove Duplicate Availability",
          dialogButtonLabel: "REMOVE",
        },
        no_rates: {
          name: "Remove availability if no rates",
          dialogButtonLabel: "REMOVE",
        },
        debug: {
          name: "DEBUG Availability",
        },
      },

      navigate: {
        label: "Links",
        counter: {
          name: "Open in Workspace",
          dialogButtonLabel: "Open",
        },
      },
      cancel: "Cancel",
    },
    alerts: {
      levelChoices: {
        high: { id: "high", name: "High" },
        medium: { id: "medium", name: "Medium" },
        low: { id: "low", name: "Low" },
      },
      locationChoices: {
        frontdesk: { id: "frontdesk", name: "Frontdesk" },
        workspace: { id: "workspace", name: "Workspace" },
      },
      buttons: {
        addNewAlert: { label: "Add New Alert" },
      },
      richText: { label: "Message" },
    },
    accountPlan: {
      label: "Account Plan",
      title: "Edit Account Plan",
      free: { id: "free", name: "Free Plan" },
      plus: { id: "plus", name: "Plus Plan" },
      pro: { id: "pro", name: "Pro Plan" },
      button: { dialogButtonLabel: "SET PLAN" },
    },
    popover: {
      activateAlert: { name: "activate alert" },
      deactivateAlert: { name: "deactivate alert" },
      deleteAlert: { name: "delete alert" },
    },
    notifications: {
      success: "Success",
      badRequest: "Bad Request",
      error: "Error",
      changePassword: {
        success: "Password changed successfully ",
        error: "Failed to change password",
      },
    },
    property: {
      title: "Property",
      propertyName: { label: "Property Name" },
      propertyId: { label: "Property ID" },
      searchInput: { label: "Search property by name, email or exact id" },
      hwIdInput: { label: "Search property by HW ID" },
      shouldDelete: {
        label: "Show Properties marked as deleted",
        true: "DELETED",
        false: "ACTIVE",
      },
    },
    spaces: {
      buttons: {
        goToProperty: { label: "Go to Property" },
        openInCounter: { label: "Open in Counter" },
      },
      searchByPropertyIDInput: { label: "Search space by Property ID" },
    },
    searchInput: { label: "Search property by name, email or exact id" },
    users: {
      dialogTitle: "Reset Pasword",
      buttons: {
        dialogButtonLabel: "Confirm",
      },
      inputLabel: "New Password",
      searchUsers: { label: "Search users by name or email" },
      isSystemAdmin: { label: "Show only System Admins" },
      addAdmin: {
        label: "Grant Admin Privileges",
        success: "Admin privileges granted successfully",
        error: "Error granting admin privileges",
      },
      removeAdmin: {
        label: "Revoke Admin Privileges",
        success: "Admin privileges revoked successfully",
        error: "Error revoking admin privileges",
      },
    },
    dialogContent: {
      propertyName: "Property Name",
      spaceName: "Space Name",
      propertyId: "Property Id",
      spaceId: "Space Id",
      userName: "User Name",
      userId: "User Id",
      userEmail: "User Email",
    },
    currency: {
      dialogButtonLabel: "Set Currency",
      title: "Edit Currency",
      label: "Currency",
    },
    emails: {
      title: "Emails",
    },
  },
};

export default messages;
