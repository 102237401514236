import { useEffect, useState } from "react";
import {
  useRecordContext,
  EditButton,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";

import { useDialogData, useAccountPlan } from "../../context/dialogContext";

import CustomDialog from "../../components/CustomDialog/CustomDialog";
import AccountPlanDialog from "../../components/Dialogs/AccountPlanDialog";

import { useStyles } from "./Space.styles";

const AccountEditButton = () => {
  const [open, setOpen] = useState(false);

  const { setDialogData } = useDialogData();
  const { accountPlan, setAccountPlan } = useAccountPlan();

  const spaceRecord = useRecordContext();
  const { onboardingStatus = "free", name, id } = spaceRecord;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const title = "ca.accountPlan.title";

  useEffect(() => {
    if (open) {
      setAccountPlan(onboardingStatus);
      setDialogData({ spaceName: name, spaceId: id, title });
    }
  }, [id, name, onboardingStatus, open, setAccountPlan, setDialogData]);

  const updatePlanAction = ({ accountPlan }) => {
    return dataProvider
      .update("subscription", {
        id,
        data: { onboardingStatus: accountPlan },
      })
      .then(function () {
        notify("ca.notifications.success", { type: "success" });
        refresh();
      })
      .catch(function (e) {
        const errorMessage = e.message || "ca.notifications.error";
        notify(errorMessage, { type: "error" });
      });
  };

  const classes = useStyles();

  return (
    <>
      <EditButton
        variant="outlined"
        size="medium"
        className={`${classes.editButton} ${classes.buttonRoot}`}
        label={onboardingStatus}
        onClick={() => setOpen(true)}
      />
      <CustomDialog
        open={open}
        setOpen={setOpen}
        buttonLabel={"ca.accountPlan.button.dialogButtonLabel"}
        onSubmit={() => updatePlanAction({ accountPlan })}
      >
        <AccountPlanDialog />
      </CustomDialog>
    </>
  );
};

export default AccountEditButton;
