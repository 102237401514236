import { makeStyles } from "@material-ui/core/styles";

export const selectProps = {
  MenuProps: {
    PaperProps: {
      sx: {
        "& > ul": {
          "& > li:nth-of-type(1)": {
            display: "none",
          },
        },
      },
    },
  },
};

export const useStyles = makeStyles(() => ({
  selectRoot: {
    margin: "0px!important",
  },
}));
