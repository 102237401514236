import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

import { useTextField } from "../../context/dialogContext";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const OptionsList = ({ propertyId, type = "roomTypes" }) => {
  const [options, setOptions] = useState([]);
  const { textFieldValue, setTextFieldValue } = useTextField();

  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchRoomTypes = async () => {
      const options = await dataProvider.getList(type, {
        propertyId,
      });
      setOptions(options.data);
    };

    fetchRoomTypes();
  }, [propertyId, dataProvider, setOptions, type]);

  return (
    <>
      <RadioGroup
        aria-labelledby="roomType-radio-buttons-group-label"
        name="radio-buttons-group"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
      >
        {options &&
          options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio />}
              label={option.name + " ( " + option._id + " ) "}
            />
          ))}
      </RadioGroup>
    </>
  );
};

export default OptionsList;
