import { useTranslate } from "react-admin";
import { useDialogData } from "../../../context/dialogContext";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { currencyList } from "../../../pages/Space/spaceConfig";

function CurrencySelect() {
  const {
    dialogData: { currency = "" },
    setDialogData,
  } = useDialogData();
  const translate = useTranslate();

  const handleChange = (e) => {
    setDialogData((prev) => ({ ...prev, currency: e.target.value }));
  };

  return (
    <FormControl fullWidth size="small" variant="filled">
      <InputLabel>{translate("ca.currency.label")}</InputLabel>
      <Select size="medium" value={currency} onChange={handleChange}>
        {currencyList &&
          currencyList.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default CurrencySelect;
