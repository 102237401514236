import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

export const useStyles = makeStyles(() => ({
  popoverStyle: {
    "& .MuiPaper-root": {
      width: "190px!important",
      height: "90px!important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around ",
      alignItems: "center",
      padding: "5px",

      "& > span:nth-of-type(1)": {
        width: "100%",
        "& > button": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "rgba(56, 142, 60, 0.3)",
            color: `${colors.primary}`,
          },
        },
      },
      "& > span:nth-of-type(2)": {
        width: "100%",
        "& > button": {
          "&:hover": {
            backgroundColor: "rgba(211, 47, 47, 0.3)",
            color: `${colors.primary}`,
          },
        },
      },
    },
  },
}));
