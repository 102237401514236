import { useTranslate } from "react-admin";

import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const AccountPlanSelect = ({ plan, setPlan }) => {
  const translate = useTranslate();

  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, marginTop: 4 }}>
      <FormControl fullWidth size="small" variant="filled">
        <InputLabel>{translate("ca.accountPlan.label")}</InputLabel>
        <Select
          size="medium"
          value={plan}
          onChange={handleChange}
        >
          <MenuItem value={"free"}>
            {translate("ca.accountPlan.free.name")}
          </MenuItem>
          <MenuItem value={"plus"}>
            {translate("ca.accountPlan.plus.name")}
          </MenuItem>
          <MenuItem value={"pro"}>
            {translate("ca.accountPlan.pro.name")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default AccountPlanSelect;
