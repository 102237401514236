import {
  TextField,
  Datagrid,
  List,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
} from "react-admin";

export const UnallocatedReservationLogList = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="reservation.guestName" />
      <DateField source="reservation.createdAt" />
      <DateField source="reservation.checkIn" />
      <TextField source="bookingSource" />
      <TextField source="channel" />
      <TextField source="property.name" />
      <TextField source="reason" />
      <TextField source="comment" />
      <BooleanField source="isOverbooking" />
      <ArrayField source="updatedBy">
        <SingleFieldList>
          <ChipField source="user" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);
