import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

export const useStyles = makeStyles(() => ({
  title: {
    color: colors.primary,
    fontSize: 24,
  },
  spacer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));
