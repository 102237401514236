import { colors } from "../../theme";
export const locationChoices = [
  {
    id: "ca.alerts.locationChoices.frontdesk.id",

    name: "ca.alerts.locationChoices.frontdesk.name",
  },
  {
    id: "ca.alerts.locationChoices.workspace.id",
    name: "ca.alerts.locationChoices.workspace.name",
  },
];

export const locationsMap = {
  frontdesk: {
    id: "ca.alerts.locationChoices.frontdesk.id",
    name: "ca.alerts.locationChoices.frontdesk.name",
  },
  workspace: {
    id: "ca.alerts.locationChoices.workspace.id",
    name: "ca.alerts.locationChoices.workspace.name",
  },
};

export const levelChoices = [
  {
    color: colors.red,
    id: "ca.alerts.levelChoices.high.id",
    name: "ca.alerts.levelChoices.high.name",
  },
  {
    color: colors.yellow,
    id: "ca.alerts.levelChoices.medium.id",
    name: "ca.alerts.levelChoices.medium.name",
  },
  {
    color: colors.green,
    id: "ca.alerts.levelChoices.low.id",
    name: "ca.alerts.levelChoices.low.name",
  },
];

export const levelMap = {
  high: {
    color: colors.red,
    id: "ca.alerts.levelChoices.high.id",
    name: "ca.alerts.levelChoices.high.name",
  },
  medium: {
    color: colors.yellow,
    id: "ca.alerts.levelChoices.medium.id",
    name: "ca.alerts.levelChoices.medium.name",
  },
  low: {
    color: colors.green,
    id: "ca.alerts.levelChoices.low.id",
    name: "ca.alerts.levelChoices.low.name",
  },
};
