import { useState } from "react";
import {
  useNotify,
  useRecordContext,
  useTranslate,
  useGetIdentity,
} from "react-admin";
import {
  useDialogData,
  useTextField,
  useDate,
} from "../../context/dialogContext";

import { getCleanupOptions } from "./cleanupOptionsConfig";

import ButtonsList from "./ButtonsList";
import CustomDialog from "../CustomDialog/CustomDialog";

import { Box, Card, CardContent, Divider } from "@mui/material";
import { useStyles } from "./Sidebar.styles";

export const Sidebar = (props) => {
  const record = useRecordContext();

  const [open, setOpen] = useState(false);
  const [actionObject, setActionObject] = useState({});
  const {
    dialogData: { getDialogComponent, buttonLabel = "" },
  } = useDialogData();
  const { textFieldValue } = useTextField();
  const { startDate, endDate } = useDate();
  const { identity: userProfile } = useGetIdentity();

  const { channex, hostelworld, myallocator, _id: propertyId } = record || {};

  const channexPropertyID =
    channex && channex[0] && channex[0].channex_PropertyID;
  const hostelworldPropertyID =
    hostelworld && hostelworld[0] && hostelworld[0].propertyNumber;
  const myaPropertyID =
    myallocator && myallocator[0] && myallocator[0].mya_propertyID;

  const cleanupOptions = getCleanupOptions({
    userProfile,
    propertyId,
    channexPropertyID,
    myaPropertyID,
    hostelworldPropertyID,
  });

  const classes = useStyles();
  const notify = useNotify();
  const t = useTranslate();

  const onSubmit = async ({
    textFieldValue,
    propertyId,
    startDate,
    endDate,
  }) => {
    const token = localStorage.getItem("token");
    const { getUrl, method, getParams, successCallback, errorCallback } =
      actionObject;

    const properties = {
      propertyId,
      roomTypeId: textFieldValue,
      sourceRsvnID: textFieldValue,
      propertyNumber: textFieldValue,
      startDate,
      endDate,
    };

    const url = getUrl(properties);

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const params = getParams && getParams(properties);

    return fetch(url, {
      headers,
      method,
      body: params && JSON.stringify(params),
    })
      .then(async (res) => {
        const { status, statusText } = res;
        if (status >= 200 && status < 300) {
          successCallback && successCallback(res);
          return notify("ca.notifications.success", {
            type: "success",
          });
        }

        const data = await res.json();
        const { error } = data;
        const errorMessage = error.message || statusText;
        errorCallback && errorCallback(error);
        notify(errorMessage, { type: "error" });
        throw new Error(errorMessage);
      })
      .catch((error) => {
        errorCallback && errorCallback(error);
        const errorMessage = error.message || "ca.notifications.badRequest";
        notify(errorMessage, { type: "error" });
      });
  };

  return (
    <Box className={classes.boxWrapper}>
      <Card>
        <CardContent className={classes.cardContent}>
          {cleanupOptions &&
            cleanupOptions.map(({ name, buttons }) => {
              const activeButtons =
                buttons && buttons.filter((button) => !button.isHidden);

              const hasActiveButtons =
                activeButtons && activeButtons.length > 0;
              return (
                hasActiveButtons && (
                  <Box key={name} className={classes.listAndTitleBox}>
                    <h4>{t(name)}</h4>
                    <ButtonsList
                      elements={activeButtons}
                      handleOpenDialog={() => setOpen((prev) => !prev)}
                      handleSetActionObject={setActionObject}
                    />
                    <Divider flexItem sx={{ marginTop: "2px" }} />
                  </Box>
                )
              );
            })}
        </CardContent>
      </Card>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        buttonLabel={buttonLabel}
        onSubmit={() =>
          onSubmit({ textFieldValue, startDate, endDate, propertyId })
        }
      >
        {open && getDialogComponent()}
      </CustomDialog>
    </Box>
  );
};
