import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold!important",
    paddingBottom: '10px',
  },
  property: {
    paddingBottom: '5px',
  },
  resetPassBox: {
    display: "flex",
    flexDirection: "column",
    padding: '5px',
  },
}));
