import { useTranslate } from "react-admin";

import { useTextField } from "../../context/dialogContext";

import PropertyDialogContent from "./DialogContent/PropertyDialogContent";
import CustomTextField from "../CustomTextField/CustomTextField";

import Box from "@mui/material/Box";

const PullHwReservation = ({ inputLabel }) => {
  const { textFieldValue, setTextFieldValue } = useTextField();
  const translate = useTranslate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
      <PropertyDialogContent />
      <CustomTextField
        label={translate(inputLabel)}
        inputValue={textFieldValue}
        setInputValue={setTextFieldValue}
      />
    </Box>
  );
};

export default PullHwReservation;
