export const SPACE_ROUTE = "space";
export const PROPERTY_ROUTE = "property";
export const COUNTER_API =
  process.env.REACT_APP_COUNTER_API || "https://api.counter.app";
export const WORKSPACE_API =
  process.env.REACT_APP_WORKSPACE_API || "https://workspace-api.counter.app";
export const WORKSPACE_URL =
  process.env.REACT_APP_WORKSPACE_URL || "https://workspace.dev.counter.app";
export const METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
};
