import Box from "@mui/material/Box";

import SpaceDialogContent from "./DialogContent/SpaceDialogContent";
import CurrencySelect from "../AccountPlanSelect/CurrencySelect/CurrencySelect";

function CurrencyDialog() {
  return (
    <Box>
      <SpaceDialogContent />
      <CurrencySelect />
    </Box>
  );
}

export default CurrencyDialog;
