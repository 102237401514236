import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

export const useStyles = makeStyles(() => ({
  buttonRoot: {
    textTransform: "none!important",
    fontSize: 13,
    fontWeight: "bold!important",
    lineHeight: 1.5,
  },
  linkButton: {
    color: `${colors.primary}!important`,
  },
  showButton: {
    width: "90px",
    "&:hover": {
      borderColor: `${colors.primary}!important`,
    },
  },
  showList: {
    "& .RaSimpleShowLayout-stack": {
      "& > span": {
        border: `1px solid ${colors.default}`,
        padding: 8,

        borderRadius: 5,
        "& > p": {
          fontWeight: "bold!important",
        },
        "& > span": {
          fontWeight: "bold!important",
        },
      },
    },
  },
}));
