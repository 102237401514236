import AlertsList from "./AlertsList";
import AlertsCreate from "./AlertsCreate";
import AlertsEdit from "./AlertsEdit";
import AddAlertIcon from "@mui/icons-material/AddAlert";

const AlertPages = {
  list: AlertsList,
  create: AlertsCreate,
  edit: AlertsEdit,
  icon: AddAlertIcon,
};

export default AlertPages;
