import { useAccountPlan } from "../../context/dialogContext";

import SpaceDialogContent from "./DialogContent/SpaceDialogContent";
import AccountPlanSelect from "../AccountPlanSelect/AccountPlanSelect";
import Box from "@mui/material/Box";

function AccountPlan() {
  const { accountPlan, setAccountPlan } = useAccountPlan();
  return (
    <Box>
      <SpaceDialogContent />
      <AccountPlanSelect plan={accountPlan} setPlan={setAccountPlan} />
    </Box>
  );
}

export default AccountPlan;
