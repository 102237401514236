import moment from "moment";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  DateField,
  FilterForm,
  TopToolbar,
  DateInput,
  NumberField,
  useCreate,
  required,
  useListContext,
} from "react-admin";
import { useSearchParams } from "react-router-dom";

//mui
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import UpdateIcon from "@mui/icons-material/UpdateOutlined";

const RESOURCE_NAME = "debugAvailability";

const isAfter = (dependsOn) => (value, allValues) => {
  const startDate = allValues && allValues[dependsOn];
  const isAfter = startDate && moment(value).isAfter(startDate);
  if (!isAfter) return "End date must be after start date";
  return undefined;
};

const searchFilter = [
  <DateInput
    label="Start Date"
    validate={[required()]}
    source="startDate"
    alwaysOn
  />,
  <DateInput
    label="End Date"
    source="endDate"
    alwaysOn
    validate={[required(), isAfter("startDate")]}
  />,
];

const DebugAvailabilityActions = () => {
  const { filterValues, refetch } = useListContext();

  const [create, { isLoading }] = useCreate(RESOURCE_NAME);

  const refreshAvailability = () =>
    create(
      RESOURCE_NAME,
      { meta: { filterValues } },
      { onSuccess: () => refetch() }
    );

  return (
    <TopToolbar
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <FilterForm filters={searchFilter} />
        <LoadingButton
          variant="contained"
          startIcon={<UpdateIcon />}
          onClick={() => refreshAvailability()}
          loading={isLoading}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "220px",
            height: "50px",
            margin: "10px 3px",
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          RECALCULATE AVAILABILITY
        </LoadingButton>
      </Box>
    </TopToolbar>
  );
};

const debugRowStyle = (record, index) => ({
  backgroundColor: !!record.allGood
    ? index % 2 === 0
      ? "#efe"
      : "#fff"
    : "red",
});

const PropertyDebugAvailabilities = (props) => {
  const [searchParams] = useSearchParams();
  const filterString = searchParams.get("filter");
  const filter = filterString && JSON.parse(filterString);
  const timeZone = filter.timezone || "UTC";

  return (
    <List
      resource={RESOURCE_NAME}
      filter={{
        disablePagination: true,
      }}
      pagination={false}
      actions={<DebugAvailabilityActions />}
      title="DEBUG Availabilities and Allocations"
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Room Name" />
        <TextField source="roomTypeID" label="Room Type ID" />
        <ArrayField source="occupiedMatrix">
          <Datagrid rowStyle={debugRowStyle} bulkActionButtons={false}>
            <DateField source="date" options={{ timeZone }} />
            <TextField source="allGood" />
            <NumberField source="totalAccommodations" />
            <NumberField source="calculatedAvailable" />
            <NumberField source="availableInSystem" />
            <NumberField source="allocCount" />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export default PropertyDebugAvailabilities;
