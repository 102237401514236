import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  Toolbar,
  SaveButton,
  useRecordContext,
} from "react-admin";

import { Sidebar } from "../../components/Sidebar/Sidebar";

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: "flex", justifyContent: "space-between" }}>
    <SaveButton />
  </Toolbar>
);

const PropertyTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record?.name}` : ""}</span>;
};

export const PropertyEdit = () => (
  <Edit
    title={<PropertyTitle />}
    aside={<Sidebar />}
    mutationMode="pessimistic"
  >
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="email" required />
      <TextInput source="currency" />
      <TextInput source="timezone" disabled />
      <TextInput source="address.country" disabled />
      <TextInput source="dateFormat" disabled />
      <SelectInput
        source="allocatorType"
        choices={[
          { id: "intelligent", name: "Intelligent Allocator" },
          { id: "efficient", name: "Flexible Efficient Allocator" },
          { id: "efficient-strict", name: "Strict Efficient Allocator" },
        ]}
      />
      <BooleanInput source="autoCustomerTag" />
      <BooleanInput
        source="meta.enableNewReservationsService"
        label="Use New Reservations Service"
      />
      <NumberInput source="code" disabled />
    </SimpleForm>
  </Edit>
);
