import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStyles } from "./App.styles";
import { colors } from "../../theme";

import logo from "../../assets/logo.png";

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar sx={{ backgroundColor: colors.appBar }} {...props}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyItems: "flex-start",
          alignItems: "center",
          paddingLeft: 5,
        }}
      >
        <img
          alt="logo"
          src={logo}
          style={{ width: "40px", height: "40px", paddingRight: "5px" }}
        />

        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
          sx={{ fontWeight: "bold" }}
        />
      </Box>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
