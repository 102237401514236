import {
  List,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  SelectInput,
  FilterForm,
  TextInput,
  useTranslate,
} from "react-admin";

import { deliveryStatuses } from "./emails.config";

const EmaillActions = () => {
  const searchFilter = [
    <SelectInput
      source="deliveryStatus"
      choices={deliveryStatuses}
      optionText="name"
      emptyValue={undefined}
      alwaysOn
    />,
    <TextInput source="to" alwaysOn sx={{ marginTop: "3px" }} />,
    <TextInput source="from" alwaysOn sx={{ marginTop: "3px" }} />,
    <TextInput
      source="reservationID"
      label="Reservation ID"
      alwaysOn
      sx={{ marginTop: "3px" }}
    />,
  ];
  return (
    <TopToolbar
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <FilterForm
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 1,
        }}
        filters={searchFilter}
      />
    </TopToolbar>
  );
};

function EmailsList() {
  const translate = useTranslate();
  return (
    <List
      actions={<EmaillActions />}
      exporter={false}
      title={translate("ca.emails.title")}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="to" />
        <TextField source="from" />
        <TextField source="subject" />
        <TextField source="reservationID" label="Reservation ID" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="deliveryStatus" />
      </Datagrid>
    </List>
  );
}

export default EmailsList;
