import { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  FunctionField,
  ShowGuesser,
  BooleanInput,
  TopToolbar,
  FilterForm,
  TextInput,
  useDataProvider,
  useTranslate,
  BooleanField,
  Button,
  useNotify,
} from "react-admin";
import PersonIcon from "@mui/icons-material/Person";
import { Chip } from "@mui/material";

import { useDialogData, useTextField } from "../../context/dialogContext";
import CustomDialog from "../../components/CustomDialog/CustomDialog";

import ResetPasswordDialog from "../../components/Dialogs/ResetPasswordDialog";
import { userDialogConfig } from "./userDialogConfig";

const UsersActions = () => {
  const translate = useTranslate();

  const searchFilter = [
    <TextInput
      label={translate("ca.users.searchUsers.label")}
      source="q"
      alwaysOn
    />,
    <BooleanInput
      label={translate("ca.users.isSystemAdmin.label")}
      source="isSystemAdmin"
      alwaysOn
    />,
  ];
  return (
    <TopToolbar
      sx={{
        width: "100%",
      }}
    >
      <FilterForm filters={searchFilter} />
    </TopToolbar>
  );
};

const UsersList = () => {
  const [open, setOpen] = useState(false);
  const { setDialogData, dialogData } = useDialogData();
  const { textFieldValue } = useTextField();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const t = useTranslate();
  const anchorOrigin = { vertical: "top", horizontal: "center" };

  const handleUserData = (id, name, email) => {
    setDialogData({
      userId: id,
      userName: name,
      userEmail: email,
      title: userDialogConfig.title,
      successMessage: `ca.notifications.changePassword.success`,
      errorMessage: `ca.notifications.changePassword.error`,
    });
    setOpen((prev) => !prev);
  };

  const giveAdminPrivileges = async ({ id, email }) => {
    await dataProvider.create("wdAdmin", { data: { id } }).then(async (res) => {
      await dataProvider
        .create("admin", { data: { id, email } })
        .then(() => {
          notify("ca.users.addAdmin.success", {
            type: "info",
            anchorOrigin,
          });
        })
        .catch((error) => {
          notify("ca.users.addAdmin.error", {
            type: "error",
            anchorOrigin,
          });
        });
    });
  };

  const removeAdminPrivileges = async ({ id }) => {
    try {
      await dataProvider.delete("wdAdmin", { id }).then(async () => {
        await dataProvider.delete("admin", { id }).then(() => {
          notify("ca.users.removeAdmin.success", {
            type: "info",
            anchorOrigin,
          });
        });
      });
    } catch (error) {
      notify("ca.users.removeAdmin.error", {
        type: "error",
        anchorOrigin,
      });
    }
  };

  const onChangePassword = async () => {
    const data = {
      userID: dialogData.userId,
      password: textFieldValue,
    };
    return dataProvider.changePassword(data);
  };

  return (
    <>
      <List exporter={false} actions={<UsersActions />}>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            source="space"
            label="Space ID"
            render={(record) => {
              const spaces = record.space || [];
              const renderChips = (text, i) => (
                <Chip key={`${text}-${i}`} label={text} />
              );

              return <>{spaces.map((space, i) => renderChips(space, i))}</>;
            }}
          />
          <TextField source="name" />
          <EmailField source="email" />
          <DateField source="createdAt" />
          <BooleanField source="isSystemAdmin" />
          <BooleanField source="canEditAsAdmin" label="Has edit rights?" />
          <FunctionField
            render={(record) => {
              const { id, email, isSystemAdmin } = record;
              return isSystemAdmin ? (
                <Button
                  style={{ color: "red" }}
                  variant="outlined"
                  size="small"
                  color="error"
                  label={t("ca.users.removeAdmin.label")}
                  onClick={() => removeAdminPrivileges({ id })}
                />
              ) : (
                <Button
                  variant="contained"
                  style={{ color: "white" }}
                  size="small"
                  color="secondary"
                  label={t("ca.users.addAdmin.label")}
                  onClick={() => giveAdminPrivileges({ id, email })}
                />
              );
            }}
          />
          <FunctionField
            render={(record) => {
              const { id, name, email } = record;
              return (
                <Button
                  variant="outlined"
                  size="small"
                  label="Reset Password"
                  onClick={() => handleUserData(id, name, email)}
                />
              );
            }}
          />
        </Datagrid>
      </List>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        buttonLabel={userDialogConfig.buttonLabel}
        //add logic on submit resset pass
        onSubmit={onChangePassword}
      >
        <ResetPasswordDialog />
      </CustomDialog>
    </>
  );
};

const UsersPages = {
  list: UsersList,
  icon: PersonIcon,
  show: ShowGuesser,
};

export default UsersPages;
