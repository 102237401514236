import { Admin, ListGuesser, Resource } from "react-admin";
import { BrowserRouter } from "react-router-dom";
import { QueryClient } from "react-query";

//auth and data providers
import { authProvider } from "./providers/authProvider";
import i18nProvider from "./providers/i18n";
import dataProvider from "./providers/dataProvider";

//property
import Property from "./pages/Property";

//alerts
import Alert from "./pages/Alert";

//users
import Users from "./pages/Users/Users";

//
import Emails from "./pages/Emails";

//monitoring
import Monitoring from "./pages/Monitoring/Monitoring";

//spaces
import { SpacesList } from "./pages/Space/SpaceList";

import PropertyDebugAvailabilityList from "./pages/Debug/Availability/list";

//mui
import MonitorIcon from "@mui/icons-material/Monitor";
import HistoryIcon from "@mui/icons-material/History";
import WarningIcon from "@mui/icons-material/WarningAmber";

import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";

import { theme } from "./theme";

//app bar
import MyLayout from "./components/MyLayout/MyLayout";

import "./App.css";
import { UnallocatedReservationLogList } from "./pages/UnallocatedReservationLogs";
import { UnallocatedreservationlogEdit } from "./pages/UnallocatedReservationLogs/edit";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1 * 60 * 1000, // 1 minute
      },
    },
  });
  return (
    <div className="App">
      <BrowserRouter>
        <Admin
          layout={MyLayout}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          theme={theme}
          queryClient={queryClient}
          requireAuth
        >
          <Resource name="spaces" list={SpacesList} icon={HolidayVillageIcon} />
          <Resource
            name="properties"
            {...Property}
            recordRepresentation={(record) => `${record.name}`}
          />

          <Resource name="alerts" {...Alert} />
          <Resource
            name="monitoring"
            list={ListGuesser}
            icon={MonitorIcon}
            show={Monitoring}
          />
          <Resource name="users" {...Users} />
          <Resource
            name="debugAvailability"
            icon={HistoryIcon}
            options={{ label: "Update Availability" }}
            list={PropertyDebugAvailabilityList}
          />
          <Resource name="emails" {...Emails} />
          <Resource
            name="unallocatedReservationLogs"
            icon={WarningIcon}
            options={{ label: "Potential Overbookings" }}
            list={UnallocatedReservationLogList}
            edit={UnallocatedreservationlogEdit}
          />
          <Resource name="hwProperty" />
        </Admin>
      </BrowserRouter>
    </div>
  );
}

export default App;
