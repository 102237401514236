import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

export const useStyles = makeStyles(() => ({
  boxWrapper: {
    marginLeft: "1em",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  listAndTitleBox: {
    padding: 2,
    display: "flex",
    flexDirection: "column",
    marginBottom: "3px",
  },
  buttonsListWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonsList: {
    display: "flex",
    justifyContent: "flex-start",
    lineHeight: 0,
    alignItems: "center",
    width: "220px",
    height: "50px",
    margin: "10px 3px",
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.secondary,
    },
  },
}));
