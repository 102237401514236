import PropertyDialogContent from "./DialogContent/PropertyDialogContent";

import Box from "@mui/material/Box";

const RefreshComponent = () => {
  return (
    <Box>
      <PropertyDialogContent />
    </Box>
  );
};

export default RefreshComponent;
