import { useTranslate } from "react-admin";
import { useDialogData } from "../../../context/dialogContext";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/lab/Alert";
import { useStyles } from "./DialogContent.styles";

const PropertyDialogContent = () => {
  const { dialogData } = useDialogData();
  const translate = useTranslate();
  const classes = useStyles();
  const {
    propertyName,
    propertyId,
    dialogTitle,
    dialogSubtitle,
    dialogWarning,
    title,
  } = dialogData;
  return (
    <Box>
      <Typography variant="h5" className={classes.title}>
        {translate(dialogTitle ? dialogTitle : title)}
      </Typography>
      {dialogSubtitle && (
        <Typography style={{ marginBottom: 20 }} variant="body1">
          {translate(dialogSubtitle)}
        </Typography>
      )}
      {dialogWarning && (
        <Alert style={{ marginBottom: 20 }} severity="warning">
          {translate(dialogWarning)}
        </Alert>
      )}

      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.propertyName"
      )}: ${propertyName} `}</Typography>
      <Typography variant="body2" className={classes.property}>{`${translate(
        "ca.dialogContent.propertyId"
      )}: ${propertyId} `}</Typography>
    </Box>
  );
};
export default PropertyDialogContent;
