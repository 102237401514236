import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const CustomTextField = ({ type, inputValue, setInputValue, label }) => {
  return (
    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <TextField
        label={label}
        type={type}
        variant="outlined"
        size="small"
        value={inputValue}
        fullWidth
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Box>
  );
};

export default CustomTextField;
