import { useState, useEffect } from "react";

import {
  useRecordContext,
  EditButton,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { useDialogData } from "../../context/dialogContext";

import CustomDialog from "../../components/CustomDialog/CustomDialog";
import CurrencyDialog from "../../components/Dialogs/CurrencyDialog";
import { useStyles } from "./Space.styles";

function CurrencyButton() {
  const [open, setOpen] = useState(false);
  const {
    dialogData: { currency: updatedCurrency },
    setDialogData,
  } = useDialogData();
  const notify = useNotify();
  const refresh = useRefresh();
  const spaceRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const { name, id, currency, propertyID } = spaceRecord;

  const classes = useStyles();
  const title = "ca.currency.title";

  useEffect(() => {
    if (open) {
      setDialogData({
        spaceName: name,
        spaceId: id,
        propertyID,
        title,
        currency,
      });
    }
  }, [currency, id, name, open, propertyID, setDialogData]);

  const updateSpaceCurrency = async ({ updatedCurrency }) => {
    if (updatedCurrency === currency) return;

    try {
      await dataProvider.update("updateSpaceCurrency", {
        id,
        data: { currency: updatedCurrency },
      });
      await dataProvider.update("updatePropertyCurrency", {
        id: propertyID,
        data: { currency: updatedCurrency },
      });

      notify("ca.notifications.success", { type: "success" });
      refresh();
    } catch (e) {
      const errorMessage = e.message || "ca.notifications.error";
      notify(errorMessage, { type: "error" });
    }
  };

  return (
    <>
      <EditButton
        className={`${classes.editButton} ${classes.buttonRoot}`}
        variant="outlined"
        size="medium"
        label={currency}
        onClick={() => setOpen((prev) => !prev)}
      />
      <CustomDialog
        open={open}
        setOpen={setOpen}
        buttonLabel={"ca.currency.dialogButtonLabel"}
        onSubmit={() => updateSpaceCurrency({ updatedCurrency })}
      >
        <CurrencyDialog />
      </CustomDialog>
    </>
  );
}

export default CurrencyButton;
