import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  SimpleList,
  TextInput,
  FilterForm,
  TopToolbar,
  useTranslate,
  DateField,
  FunctionField,
  BulkUpdateWithConfirmButton,
  useDataProvider,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Pagination,
  useNotify,
  useGetIdentity,
} from "react-admin";

//mui
import { useMediaQuery, Chip, Box, Button } from "@mui/material";
import { useStyles } from "./Property.styles";
import LaunchIcon from "@mui/icons-material/Launch";
import { WORKSPACE_URL } from "../../constants";
import dataProviders from "../../providers/dataProvider";

const PropertyPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const PropertyActions = () => {
  const translate = useTranslate();

  const searchFilter = [
    <TextInput
      label={translate("ca.property.searchInput.label")}
      sx={{ minWidth: "350px" }}
      source="q"
      resettable
      alwaysOn
    />,
    <TextInput
      label={translate("ca.property.hwIdInput.label")}
      source="propertyNumber"
      resettable
      alwaysOn
    />,
  ];
  return (
    <TopToolbar
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <FilterForm filters={searchFilter} />
      </Box>
    </TopToolbar>
  );
};

const PropertyBulkActionButtons = () => (
  <>
    <BulkUpdateWithConfirmButton
      resource="channexMapping"
      label="Enable Channex Triggers"
      data={{ useQueues: true }}
      confirmTitle="Enable Channex Triggers"
    />
    <BulkUpdateWithConfirmButton
      resource="channexMapping"
      label="Disable Channex Triggers"
      data={{ useQueues: false }}
      confirmTitle="Disable Channex Triggers"
    />
    <BulkDeleteWithConfirmButton />
  </>
);

const getPropertyMapChips = ({ record = {} }) => {
  const getPropertyMapChip = (mapping, key, options = {}) => {
    const { resource, disableDelete, label, color } = options;
    const record =
      mapping && mapping.length && mapping.length > 0 && mapping[0];

    if (!record) return null;

    const labelNumber = record[key];

    if (disableDelete)
      return <Chip label={`${label || ""} ${labelNumber}`} color={color} />;

    return (
      <RemoveMappingButton
        color={color}
        label={`${label || ""} ${labelNumber}`}
        resource={resource}
        channel={label}
        number={labelNumber}
        record={{ ...record, id: record._id }}
      />
    );
  };

  const HostelWorldChip = getPropertyMapChip(
    record.hostelworld,
    "propertyNumber",
    {
      color: "primary",
      resource: "hwMapping",
      label: "HostelWorld: ",
    }
  );

  const MyaChip = getPropertyMapChip(record.myallocator, "mya_propertyID", {
    label: "MyAllocator: ",
  });

  const SiteminderChip = getPropertyMapChip(record.siteminder, "hotelCode", {
    label: "Siteminder: ",
  });

  const ChannexChip = getPropertyMapChip(record.channex, "channex_PropertyID", {
    color: "warning",
    label: "Channex",
    resource: "cnxMapping",
  });

  return (
    <>
      {HostelWorldChip}
      {ChannexChip}
      {MyaChip}
      {SiteminderChip}
    </>
  );
};

const RemoveMappingButton = ({
  record,
  resource,
  label,
  channel,
  number,
  color = "primary",
}) => {
  return (
    <DeleteWithConfirmButton
      record={record}
      resource={resource}
      confirmTitle={`Disconnect from ${channel}`}
      confirmContent={`Are you sure you want to unmap property with ID ${number}?`}
      color={color}
      label={label}
    />
  );
};

export const PropertyList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const { identity: userProfile } = useGetIdentity();

  const toggleChannexTriggers = (ids, useQueues) =>
    dataProvider.updateMany("channexMapping", {
      ids,
      data: { useQueues: !!useQueues },
    });

  const updateAdminForSpaceAndOpenCounter = (propertyId) => {
    dataProviders
      .update("spaces", {
        id: propertyId,
        data: { newAdmin: userProfile.id, propertyId },
      })
      .then(async ({ data }) => window.open(WORKSPACE_URL, "_blank"))
      .catch((error) => {
        const errorMessage = error.message || "ca.notifications.badRequest";
        notify(errorMessage, { type: "error" });
      });
  };

  return (
    <List
      exporter={false}
      actions={<PropertyActions />}
      pagination={<PropertyPagination />}
      title={translate("ca.property.title")}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => record.id}
          tertiaryText={<ShowButton />}
        />
      ) : (
        <Datagrid bulkActionButtons={<PropertyBulkActionButtons />}>
          <TextField
            source="name"
            label={translate("ca.property.propertyName.label")}
          />
          <TextField source="email" />
          <TextField
            source="_id"
            label={translate("ca.property.propertyId.label")}
          />
          <DateField source="createdAt" sortable={true} />
          <FunctionField
            render={(record) =>
              getPropertyMapChips({ record, toggleChannexTriggers })
            }
          />
          <ShowButton
            variant="outlined"
            size="small"
            source="Action"
            className={`${classes.buttonRoo} ${classes.showButton}`}
            sx={{ textTransform: "none" }}
          />
          <EditButton
            variant="outlined"
            size="small"
            source="Action"
            className={`${classes.buttonRoo} ${classes.showButton}`}
            sx={{ textTransform: "none" }}
          />
          <FunctionField
            render={(record) => (
              <Button
                variant="contained"
                onClick={() => updateAdminForSpaceAndOpenCounter(record._id)}
                size="small"
                className={`${classes.linkButton} ${classes.buttonRoot}`}
                startIcon={<LaunchIcon />}
              >
                {translate("ca.spaces.buttons.openInCounter.label")}
              </Button>
            )}
          />
        </Datagrid>
      )}
    </List>
  );
};
