import { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import UserResetPasswordContent from "./DialogContent/UserResetPasswordContent";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";

import { useTextField } from "../../context/dialogContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { textFieldValue, setTextFieldValue } = useTextField();
  const [showPassword, setShowPassword] = useState();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box>
      <UserResetPasswordContent />
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
    </Box>
  );
};

export default ResetPassword;
