import { useTranslate, useRecordContext } from "react-admin";
import { useDialogData } from "../../context/dialogContext";

import { Link } from "react-router-dom";

//mui
import { Box } from "@mui/material";
import { colors } from "../../theme";
import Button from "@mui/material/Button";
import { useStyles } from "./Sidebar.styles";

const ButtonsList = ({ elements, handleOpenDialog, handleSetActionObject }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();
  const { name, id, timezone } = record || {};
  const { setDialogData } = useDialogData();

  const handleButtonAction = (actionObject) => {
    const {
      name: title,
      buttonLabel,
      getUrl,
      method = "POST",
      getParams,
      getDialogComponent,
      successCallback,
      errorCallback,
      ...dialogProps
    } = actionObject;

    handleOpenDialog();
    setDialogData({
      getDialogComponent,
      propertyName: name,
      propertyId: id,
      timezone,
      title: title,
      buttonLabel,
      ...dialogProps,
    });

    handleSetActionObject({
      getUrl,
      method,
      getParams,
      successCallback,
      errorCallback,
    });
  };

  return (
    <Box className={classes.buttonsListWrapper}>
      {elements &&
        elements.map((actionObject, i) => {
          const {
            icon,
            name,
            getLinkTo,
            buttonColor = "primary",
          } = actionObject;

          const to =
            getLinkTo &&
            getLinkTo({ propertyID: id, timezone, propertyName: name });

          return getLinkTo ? (
            <Button
              key={i}
              variant="contained"
              startIcon={icon}
              to={to}
              component={Link}
              disabled={actionObject.disabled}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                lineHeight: 0,
                alignItems: "center",
                width: "220px",
                height: "50px",
                margin: "10px 3px",
                fontSize: 12,
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              {translate(name)}
            </Button>
          ) : (
            <Button
              key={i}
              variant="contained"
              startIcon={icon}
              to={to}
              color={buttonColor}
              component={to && Link}
              disabled={actionObject.disabled}
              onClick={() => handleButtonAction(actionObject)}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "220px",
                height: "50px",
                margin: "10px 3px",
                fontSize: 12,
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              {translate(name)}
            </Button>
          );
        })}
    </Box>
  );
};

export default ButtonsList;
