import {
  List,
  TextField,
  Datagrid,
  TextInput,
  FilterForm,
  TopToolbar,
  useRedirect,
  FunctionField,
  useNotify,
  useGetIdentity,
  useTranslate,
  DateField,
  ExportButton,
  SelectInput,
  AutocompleteInput,
} from "react-admin";

//mui
import LaunchIcon from "@mui/icons-material/Launch";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { Box, Button } from "@mui/material";
import { useStyles } from "./Space.styles";

//component
import AccountEditButton from "./AccountPlanButton";
import CurrencyButton from "./CurrencyButton";

import dataProvider from "../../providers/dataProvider";
import { WORKSPACE_URL } from "../../constants";
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const getCountryOptions = () => {

  const countries = i18nIsoCountries.getNames("en");

  const countryOptions = Object.entries(countries).map(([code, name]) => ({
    id: i18nIsoCountries.alpha2ToAlpha3(code),
    name: name,
  }));

  return countryOptions;
};

const countryOptions = getCountryOptions();

const SpaceActions = (props) => {
  const translate = useTranslate();
  const searchFilter = [
    <TextInput
      label={translate("ca.searchInput.label")}
      source="q"
      resettable
      alwaysOn
    />,
    <TextInput
      label={translate("ca.spaces.searchByPropertyIDInput.label")}
      source="propertyID"
      resettable
      alwaysOn
    />,
    <AutocompleteInput
      label="Country"
      source="country"
      resettable
      alwaysOn
      choices={countryOptions}
      filter={(searchText, choice) =>
        choice.name.toLowerCase().includes(searchText.toLowerCase())
      }
    />,
    <SelectInput
      alwaysOn
      label="Account Plan"
      source="onboardingStatus"
      choices={[
        { id: "free", name: "Free" },
        { id: "pro", name: "Pro" },
        { id: "plus", name: "Plus" },
      ]}
    />,
  ];
  return (
    <TopToolbar
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <FilterForm filters={searchFilter} />,
      </Box>
      <Box>
        <ExportButton />,
      </Box>
    </TopToolbar>
  );
};

export const SpacesList = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const { identity: userProfile } = useGetIdentity();

  const updateAdminForSpaceAndOpenCounter = (spaceId) => {
    dataProvider
      .update("spaces", { id: spaceId, data: { newAdmin: userProfile.id } })
      .then(async ({ data }) =>
        window.open(WORKSPACE_URL, "_blank")
      )
      .catch((error) => {
        const errorMessage = error.message || "ca.notifications.badRequest";
        notify(errorMessage, { type: "error" });
      });
  };

  return (
    <>
      <List
        {...props}
        filter={{ onboardingStatus: "pro" }}
        actions={<SpaceActions {...props} />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="Space Name" sortable={true} />
          <TextField source="email" sortable={true} />
          <TextField source="_id" label="Space ID" />
          <TextField source="propertyID" label="Property ID" />
          <TextField source="city" />
          <TextField source="country" />
          <DateField source="createdAt" sortable={true} />
          <CurrencyButton source="Currency" />
          <AccountEditButton label="Account Plan" source="onboardingStatus" />
          <FunctionField
            render={(record) => (
              <Button
                variant="contained"
                onClick={() => updateAdminForSpaceAndOpenCounter(record._id)}
                size="small"
                className={`${classes.linkButton} ${classes.buttonRoot}`}
                startIcon={<LaunchIcon />}
              >
                {translate("ca.spaces.buttons.openInCounter.label")}
              </Button>
            )}
          />
          <FunctionField
            render={(record) => (
              <Button
                startIcon={<OpenInBrowserIcon />}
                disabled={!record.propertyID}
                onClick={() =>
                  redirect("show", "properties", record.propertyID)
                }
                className={`${classes.linkButton} ${classes.buttonRoot}`}
                variant="contained"
                size="small"
              >
                {translate("ca.spaces.buttons.goToProperty.label")}
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};
