import { useState } from "react";
import { useNotify, useTranslate } from "react-admin";

//mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//buttons constants
import { LoadingButton } from "@mui/lab";

const CustomDialog = ({
  open,
  setOpen,
  buttonLabel,
  onSubmit,
  children,
  successMessage = `ca.notifications.success`,
  errorMessage = `ca.notifications.error`,
}) => {
  const theme = useTheme();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const translate = useTranslate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit();
      handleClose();
    } catch (e) {
      const error = e.message || errorMessage;
      notify(error, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            autoFocus
            onClick={handleClose}
          >
            {translate("ca.buttons.cancel")}
          </Button>
          <LoadingButton
            loading={loading}
            variant="outlined"
            size="medium"
            onClick={handleSubmit}
            autoFocus
          >
            {translate(buttonLabel)}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
