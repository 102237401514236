import { useDialogData } from "../../context/dialogContext";

import OptionsList from "../OptionsList";
import Box from "@mui/material/Box";
import DateDialog from "./DateDialog";

const RoomsComponent = ({ type }) => {
  const { dialogData } = useDialogData();

  const { propertyId } = dialogData;

  return (
    <Box>
      <DateDialog
        startDate={dialogData.startDate}
        endDate={dialogData.endDate}
      />
      <OptionsList propertyId={propertyId} />
    </Box>
  );
};

export default RoomsComponent;
