import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

export const useStyles = makeStyles(() => ({
  buttonRoot: {
    textTransform: "none!important",
    fontSize: 13,
    fontWeight: "bold!important",
    lineHeight: 1.5,
  },
  linkButton: {
    color: `${colors.primary}!important`,
  },
  editButton: {
    width: "90px",
    "&:hover": {
      borderColor: `${colors.primary}!important`,
    },
  },
}));
