import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment";

const CustomDatePicker = ({ label, date, setDate }) => {
  const handleSendRequest = (newValue) => {
    const formatedDate = moment(newValue).format("YYYY-MM-DD");
    setDate(formatedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ padding: "10px 10px" }}>
        <DatePicker
          label={label}
          value={date}
          onChange={(newValue) => {
            handleSendRequest(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
